























  import { Component, Prop, Vue } from 'vue-property-decorator'

  export interface SelectableOption {
    [key: string]: string
  }
  @Component
  export default class OneDropdown extends Vue {
    @Prop({
      type: String,
      default: 'id',
    })
    itemValue!: string;

    @Prop({
      type: String,
      default: 'text',
    })
    itemText!: string;

    @Prop({
      type: Array,
      required: true,
      default: [],
    })
    options!: Array<SelectableOption>

    @Prop({
      type: String,
      required: false,
      default: 'Please select an option',
    })
    placeholder!: string

    @Prop({
      type: Object,
      required: false,
      validator: prop => typeof prop === 'object' || prop === null,
      default: null,
    })
    selectedOption!: SelectableOption

    @Prop({
      type: Boolean,
      default: false,
    })
    highlightSelected!: boolean

    selected: SelectableOption = {}
    optionsShown: boolean = false
    searchFilter: string = ''

    isSelectedOption(option: SelectableOption): boolean {
      if (!this.selectedOption) { return false }
      return option[this.itemValue] === this.selectedOption[this.itemValue]
    }

    get filteredOptions() {
      const filtered: Array<SelectableOption> = []
      const regOption = new RegExp(this.searchFilter, 'ig')
      for (const option of this.options) {
        if (this.searchFilter.length < 1 || option[this.itemText].match(regOption)) {
          filtered.push(option)
        }
      }
      return filtered.sort((a: any, b: any) => {
        // sort ASC by name
        const nameA = a.text.toUpperCase() // ignore upper and lowercase
        const nameB = b.text.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        // names must be equal
        return 0
      })
    }

    selectOption(option: SelectableOption) {
      this.selected = option
      this.optionsShown = false
      this.searchFilter = option[this.itemText]
      this.$emit('selected', this.selected[this.itemValue])
    }

    showOptions() {
      this.searchFilter = ''
      this.optionsShown = true
    }

    exit() {
      if (!this.selected[this.itemValue]) {
        this.selected = {}
        this.searchFilter = ''
      } else {
        this.searchFilter = this.selected[this.itemText]
      }
      this.optionsShown = false
    }

    keyMonitor() {
      if (this.filteredOptions[0]) {
        this.selectOption(this.filteredOptions[0])
      }
    }
  };
